import Icon from '@ant-design/icons';
import { Form, Select, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { useContextReports } from '../../../context/reports';
import { Checked } from '../Icon';
import styles from './index.module.less';
import { EnumReportTypes } from '../../../types/reports';

interface IMovingTo {
  name: string;
  movingTo?: string | number;
  label?: string;
  onChange?: (value: string) => void;
}

const MovingTo = ({ name, movingTo, label, onChange }: IMovingTo) => {
  const form = useFormInstance();
  const { loans, handleReportUpdate, report } = useContextReports();
  const [value, setValue] = useState<string | undefined>(undefined);

  const handleChange = (loanId: string) => {
    setValue(loanId);
    form.setFieldValue(['movingTo', name], loanId);
    handleReportUpdate?.();
    onChange?.(loanId);
  };

  useEffect(() => {
    if (!loans) return;
    const options = form.getFieldValue(['report', 'selectOptions']);
    const selectedOption = options && JSON.parse(options)[name];

    handleChange(selectedOption || loans[0].id);
  }, [movingTo]);

  useEffect(() => {
    if (!loans) return;
    const selectedOption = form.getFieldValue(['movingTo', name]);
    const loan = loans.find((item) => item.id === selectedOption);

    if (loan?.hidden) {
      const filteredLoans = loans.filter((item) => Boolean(item.hidden) === false);

      handleChange(filteredLoans[0]?.id);
    }

  }, [loans]);
  
  useEffect(() => {
    if (!report || !loans) return;

    const filteredLoans = loans.filter((item) => !item.hidden);
    const selectedOption = form.getFieldValue(['movingTo', name]);

    if (!filteredLoans.find((loan) => loan.id === selectedOption)) {
      handleChange(filteredLoans[0]?.id);
    }
  }, [loans]);

  useEffect(() => {
    if (!report || !loans) return;

    const filteredLoans = loans.filter((item) => !item.hidden);

    if (report.reportType === EnumReportTypes.refinance
      && (name === 'breakEvenPoint' || name === 'buyDownCalculator')) {
      const loanId = filteredLoans.find((item) => item.order !== String(1))?.id;

      if (loanId) handleChange(loanId);
    } else {
      const loanId = filteredLoans.find((item) => item.order === String(1))?.id;

      if (loanId && !value) {
        handleChange(loanId);
      }
    }
  }, [report?.reportType, loans]);

  const getOptionName = (index: number, loanName: string, reportType: EnumReportTypes | undefined) => {
    if (reportType === EnumReportTypes.refinance) {
      return index === 0 ? 'Current Loan' : loanName || `REFI Option ${index}`;
    }

    return loanName || `Option ${index + 1}`;
  };

  return (
    <div className={styles.wrapper}>
      <Form.Item label={label} name={['movingTo', name]} className='movingTo label-gray m-0 w-100'>
        <Select
          size='large'
          popupClassName='select-popup'
          placement='bottomRight'
          optionLabelProp='name'
          onChange={handleChange}
          value={value}
          options={loans
            ?.map((loan, index) => ({
              value: loan.id,
              hidden: loan.hidden,
              order: loan.order,
              label: (
                <div className={styles.optionRow}>
                  <Typography.Text className={styles.optionIndex}>{index + 1}.</Typography.Text>
                  <div className={styles.optionContent}>
                    <Typography.Title level={4} className={styles.optionName}>
                      {getOptionName(index, loan.name, report?.reportType)}
                    </Typography.Title>
                    <Typography className={styles.optionLocation}>{loan.address}</Typography>
                  </div>
                  <Icon component={Checked} className={clsx(styles.optionChecked, 'optionChecked')} />
                </div>
              ),
              name: loan.name || loan.location || getOptionName(index, loan.name, report?.reportType),
            }))?.filter((loan) => Boolean(loan.hidden) !== true)?.filter(loan => {
              if ((name === 'breakEvenPoint' || name === 'buyDownCalculator')
                && report?.reportType === EnumReportTypes.refinance && loan.order === '1') {
                return false;
              }

              return true;
            })}
        />
      </Form.Item>
    </div>
  );
};

MovingTo.defaultProps = {
  movingTo: undefined,
  label: 'Loan Details',
  onChange: undefined
};

// I've removed memo(MovingTo) because it was causing an error. I'll need to investigate further.
// TODO: Check if removing memo() is not causing any issues.
export default MovingTo;