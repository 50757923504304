import { Button, Col, Form, Row, Select, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useCallback, useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import MovingTo from '../../../../Common/MovingTo';
import { useContextReports } from '../../../../../context/reports';
import {
  useReportTaxAdvantage,
  useMortgageInterest,
  IReportMortgageIntrerest,
} from '../../../../../hooks/reports';
import { JsonResult } from '../../../../../types';
import { commafy } from '../../../../../utils/text';
import CustomInput from '../../../../Common/CustomInput';
import TextWithLabel from '../../../../Common/TextWithLabel';
import { FEDERAL_TAX_RATE, FILING_STATUS_ENUM, FILING_STATUS } from '../../constants';
import ReportSection from '../Section';
import { STATE_OPTIONS, STATE_TAX_RATE } from '../../../../../stateTaxRate';

interface ITaxAdvantage {
  provided?: DraggableProvided;
}

const TaxAdvantage = ({ provided }: ITaxAdvantage) => {
  const name = 'taxAdvantage';
  const form = useFormInstance();
  const { handleLoansUpdate, loans: reportLoans, isBorrower } = useContextReports();
  const reportTaxAdvantage = useReportTaxAdvantage();
  const mortInterestValue = useMortgageInterest();
  const [loanKey, setLoanKey] = useState<number>(0);
  const [formData, setFormData] = useState<JsonResult>();
  const movingTo = useWatch(['movingTo', name], form);
  const { loans } = useContextReports();
  const [isTableShown, setIsTableShown] = useState<boolean>(true);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  // const formFillingStatus = useWatch(['loans', loanKey, 'fillingStatus'], form) as FederalTaxRate;
  const formFillingStatusInitial = useWatch(['loans', 0, 'fillingStatus'], form) as FILING_STATUS_ENUM;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const selectedState = useWatch(['loans', loanKey, 'state'], form);
  const selectedFilingStatus = useWatch(['loans', loanKey, 'fillingStatus'], form) as FILING_STATUS_ENUM;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!selectedState) return;
  
    const options = getTaxRateOptions(selectedState);
    const currentStateTaxRate = form.getFieldValue(['loans', loanKey, 'stateTaxRate']);
  
    if (options && options.length > 0) {
      const optionValues = options.map(opt => opt.value);
      const isValidRate = optionValues.includes(currentStateTaxRate);
      const defaultTaxRate = options[0].value;
  
      if (!isValidRate) {
        form.setFieldsValue({
          loans: {
            [loanKey]: {
              stateTaxRate: defaultTaxRate
            }
          }
        });
      }
    } else {
      form.setFieldsValue({
        loans: {
          [loanKey]: {
            stateTaxRate: ''
          }
        }
      });
    }

    // handleLoansUpdate?.(); // this makes additional empty loans to be created
  }, [selectedState, selectedFilingStatus]);

  useEffect(() => {
    if (!movingTo || !loans) return;

    const index = loans.findIndex(item => item.id === movingTo);

    if (index >= 0) {
      setLoanKey(index);

      if (!form.getFieldValue(['loans', loanKey])) return;

      if (form.getFieldValue(['loans', loanKey])) {
        mortInterestValue.fetch({ id: loans[loanKey]?.id }).then((response) => {
          const fetchedInterest = (response as IReportMortgageIntrerest)?.data?.mortgageInterest;

          if (fetchedInterest) {
            form.setFieldValue(['loans', loanKey, 'mortgageInterest'], fetchedInterest.toString());
          }
        });
      }
    }
  }, [movingTo, reportLoans, loans, loanKey]);

  useEffect(() => {
    if (!isSectionActive && !loans) return;

    handleChange();
  }, [isSectionActive, loanKey, loans, loans?.length, movingTo]);

  useEffect(() => {
    if (!loans || !isSectionActive || !formData ||
      Object.values(formData).some((data) => data === undefined || data === null)) {
      return;
    }

    if (!form.getFieldValue(['loans', loanKey])) {
      return;
    }

    const { realEstateTaxes, otherDeductions } = form.getFieldValue(['loans', loanKey]) || '0';
    const fillingStatus = form.getFieldValue(['loans', loanKey, 'fillingStatus']);
    const matchedStatus = FILING_STATUS.find((item) => item.price === Number(fillingStatus))?.value;

    if (matchedStatus && fillingStatus !== matchedStatus) {
      form.setFieldValue(['loans', loanKey, 'fillingStatus'], matchedStatus);
    }

    const taxRate = form.getFieldValue(['loans', 0, 'taxRate']);
    const stateTaxRate = form.getFieldValue(['loans', loanKey, 'stateTaxRate']);
    const finalAmount =  FILING_STATUS.find(item => item.value === fillingStatus)?.price ??
    FILING_STATUS.find(item => item.value === matchedStatus)?.price ?? fillingStatus
    
    const delay = 850;
    const timeoutId = setTimeout(() => {
      const mortgageInterest = form.getFieldValue(['loans', loanKey, 'mortgageInterest']);
      const updatedLoan = {
        // fillingStatus: matchedStatus || fillingStatus,
        fillingStatus: finalAmount,
        taxRate,
        stateTaxRate,
        realEstateTaxes,
        otherDeductions,
        mortgageInterest,
      };

      reportTaxAdvantage.fetch({ id: loans[loanKey].id, ...updatedLoan });
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [formData, movingTo, isSectionActive, loans, loanKey]);

  useEffect(() => {
    if (loans && loans.length > 0) {
      const currentState = form.getFieldValue(['loans', loanKey, 'state']);

      // Set default state to California
      if (!currentState) {
        form.setFieldsValue({
          loans: {
            [loanKey]: {
              ...form.getFieldValue(['loans', loanKey]),
              state: 'CA',
            },
          },
        });
      }

      const firstLoanRealEstateTaxes = loans[loanKey]?.propertyTaxes || 0;
      const firstLoanPayRate = Math.min(Number(firstLoanRealEstateTaxes) * 12, 10000);

      form.setFieldsValue({
        loans: {
          ...form.getFieldValue('loans'),
          [loanKey]: {
            ...form.getFieldValue(['loans', loanKey]),
            realEstateTaxes: firstLoanPayRate,
          },
        },
      });
    }
  }, [loans, loanKey, form]);


  const handleChange = () => {
    if (form.getFieldValue(['loans', loanKey])) {
      const { realEstateTaxes, otherDeductions, mortgageInterest } = form.getFieldValue(['loans', loanKey]) || '0';
      const { fillingStatus, taxRate, stateTaxRate } = form.getFieldValue(['loans', 0]);

      const realEstateTaxesValue = (realEstateTaxes && parseFloat(realEstateTaxes.toString()?.replace(/,/g, ''))) || 0;
      const mortgageInterestValue =
        (mortgageInterest && parseFloat(mortgageInterest.toString()?.replace(/,/g, ''))) || 0;
      
      const selectedOne = FILING_STATUS.find((item) => item.value === fillingStatus)?.price ?? fillingStatus

      setFormData({
        fillingStatus: selectedOne,
        fillingStatusString: fillingStatus,
        taxRate,
        stateTaxRate,
        realEstateTaxes: realEstateTaxesValue,
        otherDeductions,
        mortgageInterest: mortgageInterestValue,
      });
    }
  };

  const toggleTableView = () => setIsTableShown((prev) => !prev);

  useEffect(() => {
    if (isBorrower) {
      setIsTableShown(false);
    }
  }, [isBorrower])

  const handleInputBlur = () => {
    handleLoansUpdate?.();
    handleChange();
  };

  const handleUpdateBlur = () => {
    handleChange();
  }

  const getTaxRateOptions = (state?: string) => {
    if (!selectedState && !state) return [];

    const stateRates = STATE_TAX_RATE[state || selectedState];

    if (Array.isArray(stateRates)) {
      return stateRates.map(rate => ({
        value: String(rate.value),
        text: `${rate.text} , ${rate.value}%`,
      }));
    }

    if (typeof stateRates === 'object' && selectedFilingStatus && stateRates[selectedFilingStatus]) {
      return stateRates?.[selectedFilingStatus]?.map(rate => ({
        value: String(rate.value),
        text: `${rate.text} (${rate.value}%)`,
      }));
    }

    return [];
  }

  const taxRateOptions = getTaxRateOptions()

  return (
    <ReportSection
      name={name}
      id={name}
      title="Income Tax Advantage of Homeownership"
      description="Here is an estimation of the total income tax savings you could receive through homeownership.
       Please consult with a tax professional for more detailed information."
      provided={provided}
    >
      <div className="flex-col gap-20 tax-advantage" style={{ padding: '10px' }}>
        <Row gutter={windowWidth >= 768 ? [20, 20] : [0, 0]} style={{ padding: '8px' }}>
          <Col xl={12} span={24}>
            <Space size={72}>
              <TextWithLabel
                label="Tax benefit results"
                value={`$${commafy(reportTaxAdvantage.data?.data.taxBenefitPerMonth || 0)}`}
                interval="per month"
              />
              {windowWidth >= 768 ? (
                <TextWithLabel
                  value={`$${commafy(reportTaxAdvantage.data?.data.taxBenefitPerYear || 0)}`}
                  interval="per year"
                />
              ) : null}
            </Space>
          </Col>
          <MovingTo name={name} />
          {windowWidth < 768 ? (
            <Col xl={12} span={24}>
              <TextWithLabel
                value={`$${commafy(reportTaxAdvantage.data?.data.taxBenefitPerYear || 0)}`}
                interval="per year"
              />
              <MovingTo name={name} />
            </Col>
          ) : null}
        </Row>
        <Col span={6}>
          <Button type='default' size='large' onClick={toggleTableView}>
            {isTableShown ? 'Hide details' : 'Show details'}
          </Button>
        </Col>
        {isTableShown &&
          <div style={{ backgroundColor: '#e5eeff', padding: '10px' }}>
            <Row gutter={[20, 20]} style={{ padding: "10px" }}>
              <Col xl={8} span={24}>
                <Form.Item label="State" name={['loans', loanKey, 'state']} className="label-gray m-0 w-100">
                  <Select
                    size="large"
                    popupClassName="select-popup"
                    placement="bottomRight"
                    value={form.getFieldValue(['loans', loanKey, 'state']) || undefined}
                    optionLabelProp="text"
                    options={STATE_OPTIONS?.map(state => ({
                      value: state.value,
                      label: (
                        <div className="flex-row flex-justify-space-between">
                          <Typography>{state.text} , {state.value}</Typography>
                        </div>
                      ),
                      text: state.text,
                    }))}
                    onSelect={() => {
                      handleLoansUpdate?.()
                    }} />
                </Form.Item>
              </Col>
              <Col xl={8} span={24}>
                <Form.Item label="Filing Status"
                  name={['loans', loanKey, 'fillingStatus']}
                  className="label-gray m-0 w-100">
                  <Select
                    size="large"
                    popupClassName="select-popup"
                    placement="bottomRight"
                    optionLabelProp="text"
                    options={FILING_STATUS}
                    onSelect={handleInputBlur} />
                </Form.Item>
              </Col>
              <Col xl={8} span={24}>
                <Form.Item label="State Tax Rate" className="label-gray m-0 w-100"
                  name={['loans', loanKey, 'stateTaxRate']}>
                  <Select
                    value={form.getFieldValue(['loans', loanKey, 'stateTaxRate']) ?
                      `${form.getFieldValue(['loans', loanKey, 'stateTaxRate'])}%` : undefined}
                    disabled={form.getFieldValue(['loans', loanKey, 'state']) === undefined}
                    size="large"
                    popupClassName="select-popup"
                    placement="bottomRight"
                    optionLabelProp="label"
                    options={taxRateOptions?.map(rate => ({
                      value: rate.value,
                      label: rate.text
                    }))}
                    onSelect={handleInputBlur}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 20]} style={{ padding: "10px" }}>
              <Col xl={8} span={24} offset={windowWidth >= 1200 ? 8 : 0} >
                <Form.Item label="Federal Tax Rate" name={['loans', 0, 'taxRate']} className="label-gray m-0 w-100">
                  <Select
                    size="large"
                    popupClassName="select-popup"
                    placement="bottomRight"
                    optionLabelProp="text"
                    options={FEDERAL_TAX_RATE[formFillingStatusInitial || FILING_STATUS_ENUM.SINGLE]?.map((item) => ({
                      value: item.value,
                      label: (
                        <div className="flex-row flex-justify-space-between">
                          <Typography>{item.value}%</Typography>
                          <Typography>{item.taxBrackets}</Typography>
                        </div>
                      ),
                      text: item.text,
                    }))}
                    onSelect={handleInputBlur} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 20]} style={{ padding: "10px" }}>
              <Col xl={8} span={24}>
                <CustomInput
                  inputClassName="bg-white"
                  formItemProps={{
                    className: 'm-0 label-gray',
                    label: 'Property Tax',
                    name: ['loans', loanKey, 'realEstateTaxes'],
                    rules: [
                      {
                        validator: (_, value) => {
                          if (value && parseFloat(value.toString()) > 10000) {
                            return Promise.resolve();
                          }

                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                  identifier={['loans', loanKey, 'realEstateTaxes']}
                  bordered
                  prefix="$"
                  placeholder="$0"
                  onBlur={(e) => {
                    const value = String(e);

                    if (value) {
                      const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));

                      if (numericValue > 10000) {
                        form.setFieldValue(['loans', loanKey, 'realEstateTaxes'], 10000);
                      } else {
                        form.setFieldValue(['loans', loanKey, 'realEstateTaxes'], numericValue);
                      }
                      handleUpdateBlur();
                    }
                  }}
                />
              </Col>

              <Col xl={8} span={24}>
                <CustomInput
                  inputClassName="bg-white"
                  formItemProps={{
                    className: 'm-0 label-gray',
                    label: 'Mortgage Interest',
                    name: ['loans', loanKey, 'mortgageInterest'],
                  }}
                  identifier={['loans', loanKey, 'mortgageInterest']}
                  bordered
                  prefix="$"
                  placeholder="$0"
                  onBlur={handleUpdateBlur} />
              </Col>
              <Col xl={8} span={24}>
                <CustomInput
                  inputClassName="bg-white"
                  formItemProps={{
                    className: 'm-0 label-gray',
                    label: 'Other Deductions',
                    name: ['loans', loanKey, 'otherDeductions'],
                  }}
                  identifier={['loans', loanKey, 'otherDeductions']}
                  bordered
                  prefix="$"
                  placeholder="$0"
                  onBlur={handleUpdateBlur} />
              </Col>
            </Row>
          </div>
        }
      </div>
    </ReportSection>
  );
};

TaxAdvantage.defaultProps = {
  provided: undefined,
};

export default TaxAdvantage;
