import { FormInstance } from 'antd';
import { CSSProperties, ReactNode } from 'react';
import { JsonResult } from './index';

export interface IOption {
  value: number;
  label: string;
}

export interface IReportRow {
  id?: number;
  tabIndex?: number;
  order: number;
  active?: boolean;
  name: string;
  subName?: string;
  text?: string;
  details?: (isBorrower?: boolean) => ReactNode;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  static?: boolean;
  disabled?: (loan: ILoanColumn) => boolean;
  hidden?: boolean;
  options?: () => IOption[];
  bolded?: boolean;
  color?: string | null;
  additionalText?: (loan: ILoanColumn, loans?: ILoanColumn[],
    reportType?: EnumReportTypes | undefined) => { text: string; style?: CSSProperties } | undefined;
  prepareData?: (loan: ILoanColumn, loans?: ILoanColumn[], form?: FormInstance) => number | string | undefined;
  extra?: JSX.Element;
  styles?: CSSProperties;
  subField?: IReportRow;
  note?: string;
  onChange?: (form: FormInstance,
    key: number | string,
    value: string,
    handleLoansUpdate?: (event?: unknown, withLoading?: boolean) => void,
    loan?: ILoanColumn
  ) => void;
  onSelect?: (form: FormInstance, key: number | string, value: string) => void;
  getPopoverContent?: (
    field: JsonResult,
    rowsLength: number,
    form: FormInstance,
    handleLoansUpdate?: (event?: unknown, withLoading?: boolean) => void
  ) => ReactNode;
}

export interface ILoanColumn {
  [key: string]: {
    value?: number | string;
    focused?: boolean;
  };
}

export interface ILoanAdvancedValue {
  value?: number | string | null;
}

export interface ILoanValue {
  [key: string]: string | number | ILoanAdvancedValue | undefined | null;
}

export enum EnumReportTypes {
  purchase = 'purchase',
  refinance = 'refinance',
}