/* eslint-disable react/no-array-index-key */
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import Form from 'antd/es/form';
import { useWatch } from 'antd/es/form/Form';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { KeyboardEventHandler, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../../context/reports';
import { EnumReportTypes, IReportRow } from '../../../../../../types/reports';
import { getInputsCount } from '../../../../../../utils/loan';
import { Plus } from '../../../../../Common/Icon';
import Loading from '../../../../../Common/Loading';
import styles from '../index.module.less';
import ControlsCol from './ControlsCol';
import HeaderCol from './HeaderCol';
import TableCol from './TableCol';
import {
  IReportLoan,
  useReportInterestOnlyToggle,
  useReportLoanAddUip,
  useReportLoanToggle,
  useReportRecalculateTax,
} from '../../../../../../hooks/reports';
import { useContextNotifications } from '../../../../../../context/notifications';

interface IReportForm {
  disabled?: boolean;
  reportType: EnumReportTypes | undefined;
}

const keys: string[] = [];

const ReportForm = ({ disabled, reportType }: IReportForm) => {
  const form = Form.useFormInstance();
  const loans = useWatch('loans', form);
  const { openNotification } = useContextNotifications();
  const { loansLoading, handleLoansUpdate, handleReportSettingsUpdate } = useContextReports();
  const typeNewColState = useState<'area' | 'location' | null>(null);
  const [typeNewCol, setTypeNewCol] = typeNewColState;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const toggleLoanSegment = useReportLoanToggle();
  const toggleInterestOnly = useReportInterestOnlyToggle();
  const toggleAddUip = useReportLoanAddUip();
  const taxRecalculation = useReportRecalculateTax();
  const tableView: IReportRow[] = form.getFieldValue('tableView');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddLocation = () => {
    setTypeNewCol('location');
  };

  const handleClose = () => {
    setTypeNewCol(null);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return;

    const updatedColumns = [...(loans || [])];
    const [reorderedItem] = updatedColumns.splice(droppedItem.source.index, 1);

    updatedColumns.splice(droppedItem.destination.index, 0, reorderedItem);

    form.setFieldValue('loans', updatedColumns);
    handleLoansUpdate?.();
    setIsDragging(false);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (keys.find((key) => key === event.key)) return;
    keys.push(event.key);

    const container = document.getElementById('table-container');
    const inputsCollection = container?.getElementsByTagName('input');

    if (!inputsCollection) return;

    const currentTabIndex = parseInt(document.activeElement?.getAttribute('tabindex') || '', 10);

    const inputs = Array.from(inputsCollection);

    const inputsCount = getInputsCount(tableView);

    if (keys.includes('Control') && keys.includes('ArrowDown')) {
      event.preventDefault();
      const nextInputTabIndex = currentTabIndex + 1;
      const nextInput = inputs.find(
        (input) => parseInt(input.getAttribute('tabindex') || '', 10) === nextInputTabIndex
      );

      nextInput?.focus();
    }

    if (keys.includes('Control') && keys.includes('ArrowUp')) {
      event.preventDefault();
      const nextInputTabIndex = currentTabIndex - 1;

      if (nextInputTabIndex < 0) return;
      const nextInput = inputs.find(
        (input) => parseInt(input.getAttribute('tabindex') || '', 10) === nextInputTabIndex
      );

      nextInput?.focus();
    }

    if (keys.includes('Control') && keys.includes('ArrowRight')) {
      event.preventDefault();
      const nextInputTabIndex = currentTabIndex + inputsCount;
      const nextInput = inputs.find(
        (input) => parseInt(input.getAttribute('tabindex') || '', 10) === nextInputTabIndex
      );

      nextInput?.focus();
    }

    if (keys.includes('Control') && keys.includes('ArrowLeft')) {
      event.preventDefault();
      const nextInputTabIndex = currentTabIndex - inputsCount;

      if (nextInputTabIndex < 0) return;
      const nextInput = inputs.find(
        (input) => parseInt(input.getAttribute('tabindex') || '', 10) === nextInputTabIndex
      );

      nextInput?.focus();
    }
  };

  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const index = keys.findIndex((key) => key === event.key);

    if (index < 0) return;

    keys.splice(index, 1);
  };

  const onCheckboxToggle = (e: CheckboxChangeEvent, id: string | number) => {
    toggleLoanSegment.fetch({ isSegment: e.target.checked }, id);
    const loanIndex = loans?.findIndex((loan: IReportLoan) => loan.id === id);

    if (loanIndex !== -1) {
      const updatedLoans = [...loans];

      updatedLoans[loanIndex] = {
        ...updatedLoans[loanIndex],
        isSegment: e.target.checked,
      };

      form.setFieldValue('loans', updatedLoans);
      handleLoansUpdate?.();
    }
  };

  const onCheckboxInterestOnlyToggle = (e: CheckboxChangeEvent, id: string | number) => {
    toggleInterestOnly.fetch({ interestOnly: e.target.checked }, id);
    const loanIndex = loans?.findIndex((loan: IReportLoan) => loan.id === id);

    if (loanIndex !== -1) {
      const updatedLoans = [...loans];

      updatedLoans[loanIndex] = {
        ...updatedLoans[loanIndex],
        interestOnly: e.target.checked,
      };

      form.setFieldValue('loans', updatedLoans);
      handleLoansUpdate?.();
    }
  };

  const onCheckAddUipToggle = (e: CheckboxChangeEvent, id: string | number) => {
    toggleAddUip.fetch({ addUip: e.target.checked }, id);

    
    const loanIndex = loans?.findIndex((loan: IReportLoan) => loan.id === id);

    if (loanIndex !== -1) {
      const updatedLoans = [...loans];

      updatedLoans[loanIndex] = {
        ...updatedLoans[loanIndex],
        addUip: e.target.checked,
      };

      form.setFieldValue('loans', updatedLoans);
      handleLoansUpdate?.();

      // getReportSettings?.()
      // form.setFieldValue(
      //   ['loans', loanIndex, 'loanAmountWithUip'],
      //   Number(loans[loanIndex].loanAmount) + Number(loans[loanIndex].uip)
      // );
    }
  };

  const onCheckboxRecalculate = (e: CheckboxChangeEvent, id: string, zpid: string) => {
    setIsLoading(true);

    const loanIndex = loans?.findIndex((loan: IReportLoan) => loan.id === id);

    if (loanIndex === -1) {
      setIsLoading(false);

      return;
    }
    const updatedLoans = [...loans];

    taxRecalculation
      .fetch({ recalculateTax: e.target.checked, zpid, loanId: id })
      .then((res) => {
        const propertyTaxes = res?.data?.propertyTaxes;

        updatedLoans[loanIndex] = {
          ...updatedLoans[loanIndex],
          recalculateTax: e.target.checked,
          propertyTaxes: {
            value: propertyTaxes,
          },
        };

        form.setFieldValue('loans', updatedLoans);
        handleLoansUpdate?.();
      })
      .catch((error) => {
        openNotification?.({
          message: `Error during Tax calculation: ${error}`,
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="position-relative">
      <Loading visible={!!loansLoading || isLoading} absolute />
      <Form.List name="loans">
        {(fields, { remove }) => (
          <div id="table-container" className={styles.tableRow} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <HeaderCol disabled={!!disabled} />
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <Droppable droppableId="loans" direction="horizontal">
                {(provided) => (
                  <div className={styles.tableContent} {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((field, index) => {
                      if (loans?.[index]?.hidden) {
                        return null;
                      }
                      const loanId: number|undefined = loans?.[index]?.id; // Extract loanId from the loans array
                      const isFirst = index === 0;
                      const isRefinance = reportType === EnumReportTypes.refinance || false;

                      if(isFirst && isRefinance) {
                        return (
                          <TableCol
                          onCheckboxInterestOnlyToggle={onCheckboxInterestOnlyToggle}
                          onCheckAddUipToggle={onCheckAddUipToggle}
                          onCheckboxRecalculate={onCheckboxRecalculate}
                          onCheckboxToggle={onCheckboxToggle}
                          key={index}
                          field={field}
                          itemKey={index}
                          isDragging={isDragging}
                          handleRemove={() => {
                            remove(index);
                          }}
                          disabled={!!disabled}
                          isFirst={isFirst}
                          isRefinance={isRefinance}
                          reportType={reportType}
                          loanId={loanId}
                        />
                        )
                      }

                      return (
                        <Draggable key={index} draggableId={index.toString()} index={index} 
                        isDragDisabled={disabled}>
                          {(draggableProvided) => (
                            <TableCol
                              onCheckboxInterestOnlyToggle={onCheckboxInterestOnlyToggle}
                              onCheckAddUipToggle={onCheckAddUipToggle}
                              onCheckboxRecalculate={onCheckboxRecalculate}
                              onCheckboxToggle={onCheckboxToggle}
                              key={index}
                              field={field}
                              itemKey={index}
                              isDragging={isDragging}
                              provided={draggableProvided}
                              handleRemove={() => {
                                remove(index);
                              }}
                              disabled={!!disabled}
                              isFirst={isFirst}
                              isRefinance={isRefinance}
                              reportType={reportType}
                              loanId={loanId}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    {typeNewCol && !disabled && (
                      <ControlsCol typeNewColState={typeNewColState} handleClose={handleClose} />
                    )}

                    {!disabled && (
                      <div className={styles.tableCol}>
                        <div className={styles.tableHeadCell}>
                          <Button
                            type="ghost"
                            size="small"
                            icon={<Icon component={Plus} />}
                            onClick={handleAddLocation}
                          >
                            add location
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </Form.List>
    </div>
  );
};

ReportForm.defaultProps = {
  disabled: false,
};

export default ReportForm;
