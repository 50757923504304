import { Button, Empty, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { useContextNotifications } from '../../../../../context/notifications';
import {
  useCustomerPaymentMethods,
  usePaymentMethodCreate,
  usePaymentMethodDelete,
  usePaymentMethodSetAsDefault,
} from '../../../../../hooks/stripe';
import ContentCard from '../../../../Common/ContentCard';
import PaymentItem from '../../../../Common/PaymentItem';

import styles from './index.module.less';
import PaymentMethodModal from './PaymentMethodModal';

const PaymentMethod = () => {
  const { openNotification, openConfirm } = useContextNotifications();
  const [paymentMethod, setPaymentMethod] = useState<Stripe.PaymentMethod | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const customerPaymentMethods = useCustomerPaymentMethods();
  const paymentMethodSetAsDefault = usePaymentMethodSetAsDefault();
  const paymentMethodCreate = usePaymentMethodCreate();
  const paymentMethodDelete = usePaymentMethodDelete();

  const loadPaymentMethods = () => {
    customerPaymentMethods.fetch().then((response) => {
      const defaultPaymentMethod = response?.data.data.find((item) => item.isDefault);

      setPaymentMethod(defaultPaymentMethod || null);
    });
  };

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  const handlePaymentMethodChange = (pmId: string) => {
    setIsModalOpen(false);
    paymentMethodCreate
      .fetch({ pmId })
      .then(() => {
        paymentMethodSetAsDefault.fetch({ pmId });
      })
      .then(() => {
        openNotification?.({ message: 'Payment method was changed successfully' });
        loadPaymentMethods();
      })
      .then(() => {
        window.fbq('track', 'InitiateCheckout');

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'InitiateCheckout',
          'paymentMethodId': pmId
        });

        // Reddit Pixel
        window.rdt('track', 'InitiateCheckout');
      });
  };

  const handleDelete = (handleClose: () => void) => {
    if (!paymentMethod?.id) return;

    paymentMethodDelete.fetch(paymentMethod.id).then(() => {
      handleClose();
      loadPaymentMethods();
      openNotification?.({ message: 'Payment Method was deleted successfully' });
    });
  };

  return (
    <>
      <PaymentMethodModal open={isModalOpen} onOk={handlePaymentMethodChange} onCancel={() => setIsModalOpen(false)} />
      <ContentCard
        loading={customerPaymentMethods.loading || paymentMethodSetAsDefault.loading || paymentMethodCreate.loading}
        bordered
        padding={20}
        height="100%"
        footer={
          <div className={styles.footer}>
            <Space size={12} className={styles.buttonWrapper}>
              {paymentMethod && (
                <Button
                  type="default"
                  danger
                  size="large"
                  onClick={() => {
                    openConfirm?.({
                      title: 'Delete Payment Method ',
                      content: 'Are you sure you want to delete Payment Method?',
                      okText: 'Delete',
                      okType: 'danger',
                      onOk: (handleClose) => handleDelete(handleClose),
                    });
                  }}
                >
                  Delete
                </Button>
              )}

              {/* <Button
                type="default"
                className="btn-default-dark-blue"
                size="large"
                onClick={() => setIsModalOpen(true)}
              >
                {paymentMethod ? 'Change payment method' : 'Add payment method'}
              </Button> */}
            </Space>
          </div>
        }
      >
        <div className={styles.content}>
          <div>
            <Typography.Title level={3}>Payment method</Typography.Title>
            <Typography className="color-gray">Change how you pay for your plan.</Typography>
          </div>
          {paymentMethod?.card ? (
            <PaymentItem card={paymentMethod.card} />
          ) : (
            <Empty style={{ margin: 0 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description="No default payment method" />
          )}
        </div>
      </ContentCard>
    </>
  );
};

export default PaymentMethod;
