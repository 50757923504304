import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { EnumClosingCostType } from '../../../../types/settings';
import {
  useLoanClosingCostUpdate,
  useReportClosingCostCreate,
  useReportLoansClosingCosts
} from '../../../../hooks/reports';
import { useAuth } from '../../../../context/auth';
import { useContextReports } from '../../../../context/reports';
import { useContextNotifications } from '../../../../context/notifications';

const { Option } = Select;

type ClosingCostFormPopoverProps = {
  reportId: string;
  updateRows?: { text: string; amount: number; closingCostId: number; editable: boolean; type: EnumClosingCostType }[];
  setPopoverOpen?: (open: boolean) => void;
  refetchData?: () => void;
  isEditMode?: boolean;
  // eslint-disable-next-line react/require-default-props
  rowsInitializedRef?: React.MutableRefObject<boolean>;
}

const ClosingCostFormPopover: React.FC<ClosingCostFormPopoverProps> = ({
  reportId,
  updateRows,
  setPopoverOpen,
  refetchData,
  isEditMode,
  rowsInitializedRef
}) => {
  const [form] = useForm();
  const { user } = useAuth();
  const { fetch: createReportClosingCost, loading: createLoading } = useReportClosingCostCreate();
  const { fetch: updateLoanClosingCost, loading: updateLoading } = useLoanClosingCostUpdate();
  const { fetch: fetchLoansClosingCosts } = useReportLoansClosingCosts();
  const { reportClosingCosts, getLoans, setPreviewLoading } = useContextReports()
  const { openNotification } = useContextNotifications();

  useEffect(() => {
    if (!reportClosingCosts || !updateRows || !isEditMode) return

    reportClosingCosts.forEach(cost => {
      if (cost.text === updateRows[0].text && cost.type !== EnumClosingCostType.prepaidInterest15Days) {
        form.setFieldsValue({
          amount: cost.amount
        })
      }
    })
  }, [reportClosingCosts])

  useEffect(() => {
    if (updateRows && updateRows.length > 0) {
      const closingCost = updateRows[0];

      form.setFieldsValue({
        text: closingCost.text,
        type: closingCost.type
      });
    }
  }, [updateRows]);

  const onEdit = async (id: string | number, values: { text: string; type: EnumClosingCostType; amount?: number }) => {
    if (!reportId) return;
    if (!user.id) return;
    
    try {
      setPreviewLoading(true) 

      const dataToSend: any = {
        text: values.text,
        type: values.type,
        reportId
      };
  
      if (values.amount || values.amount === 0) {
        dataToSend.amount = values.amount;
      }
  
      await updateLoanClosingCost(dataToSend, id);
    } catch (error) {
      openNotification?.({
        message: 'Error',
        description: "Can't update closing cost",
        type: 'error',
      });
    } finally {
      setPreviewLoading(false)
    }
  };

  const onCreate = async (values: { text: string; type: EnumClosingCostType; amount?: number }) => {
    if (!reportId) return;
    if (!user.id) return;

    try {
      const dataToSend: any = {
        text: values.text,
        type: values.type,
        active: true,
        reportId,
        userId: Number(user.id),
        amount: values.amount
      };
  
      await createReportClosingCost(dataToSend);
    } catch (error) {
      openNotification?.({
        message: 'Error',
        description: "Can't create closing cost",
        type: 'error',
      });
    } finally {
      setPreviewLoading(false)
    }
  };

  const handleFinish = async (values: { text: string; type: EnumClosingCostType; amount?: number }) => {
    if (updateRows && updateRows.length > 0) {
      const editPromises = updateRows.map((row) =>
        onEdit(row.closingCostId, { ...values }).then(() => {
          form.setFieldsValue({
            ...values
          });
        })
      );
  
      await Promise.all(editPromises);
    } else {
      await onCreate(values);
      form.resetFields();
    }
  
    // Fetch latest closing costs immediately
    await fetchLoansClosingCosts(undefined, reportId);
    
    // Ensure data is updated in table without refresh
    if (refetchData) {
      refetchData();
    }
  
    if (setPopoverOpen) {
      setPopoverOpen(false);
    }
  
    if (rowsInitializedRef) {
      setPreviewLoading(true);
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        rowsInitializedRef.current = false;
        setPreviewLoading(false);
      }, 4000);
    }

    getLoans?.()
  };
  

  const isLoading = createLoading || updateLoading;

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Form.Item
        name="text"
        label="Text"
        rules={[{ required: true, message: 'Please enter the text' }]}
      >
        <Input placeholder="Text" />
      </Form.Item>
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: 'Please select the type' }]}
      >
        <Select placeholder="Select Type"
          onChange={(value) => {
            if (value === EnumClosingCostType.prepaidInterest15Days) {
              form.setFields([
                {
                  name: 'amount',
                  errors: [],
                },
              ]);
            }
          }}>
          <Option value={EnumClosingCostType.flatFee}>Flat Fee</Option>
          <Option value={EnumClosingCostType.percentageOfLoanAmount}>Percentage of Loan Amount</Option>
          <Option value={EnumClosingCostType.prepaidInterest15Days}>Prepaid Interest 15 Days</Option>
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
        {({ getFieldValue }) => {
          const type = getFieldValue('type');
          const prefix = type === EnumClosingCostType.flatFee ? '$' : '';
          const addonAfter = type === EnumClosingCostType.percentageOfLoanAmount ? '%' : '';
          const isPrepaidInterest = type === EnumClosingCostType.prepaidInterest15Days;

          const rules = !isEditMode && !isPrepaidInterest ? [
            {
              required: !isEditMode && !isPrepaidInterest,
              message: 'Please enter the amount',
            },
          ] : []

          return (
            <>
              <Form.Item
                name="amount"
                label="Amount"
                rules={rules}
              >
                <Input
                  name="amount"
                  type="number"
                  placeholder="Enter Amount"
                  prefix={prefix}
                  addonAfter={addonAfter}
                  disabled={isPrepaidInterest}
                />
              </Form.Item>
              {isPrepaidInterest && (
                <div style={{ color: 'gray', marginTop: '-10px' }}>
                  The amount will be calculated elsewhere.
                </div>
              )}
            </>
          );
        }}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

ClosingCostFormPopover.defaultProps = {
  updateRows: [],
  setPopoverOpen: undefined,
  refetchData: undefined,
  isEditMode: false
};

export default ClosingCostFormPopover;
