/* eslint-disable max-len */
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './successStory.module.less';

const PrevArrow = (props: any) => (
  <div {...props} className={`${styles.arrow} ${styles.prevArrow}`}>
    <LeftOutlined />
  </div>
);

const NextArrow = (props: any) => (
  <div {...props} className={`${styles.arrow} ${styles.nextArrow}`}>
    <RightOutlined />
  </div>
);

const SuccessStoryCarousel = () => (
  <Carousel
    autoplay
    autoplaySpeed={1000 * 10}
    className={styles.carouselWrapper}
    dots={false}
    prevArrow={<PrevArrow />}
    nextArrow={<NextArrow />}
    arrows
  >
    <div>
      <img src="/success-story-1.png" className={`${styles.successImage}`} alt="success story 1" />
    </div>
    <div>
      <img src="/success-story-2.png" alt="success story 2" />
    </div>
    <div>
      <img src="/success-story-3.png" alt="success story 3" />
    </div>
    <div>
      <img src="/success-story-4.png" alt="success story 4" />
    </div>
  </Carousel>
)

export default SuccessStoryCarousel