import React, { useEffect, useState, useRef } from 'react';
import SettingsList from '../SettingsList';
import { useContextUsers } from '../../../../../context/users';
import { useAuth } from '../../../../../context/auth';
import { ISettingItem } from '../../../../../hooks/users';
import ClosingCostModal from '../ClosingCostModal';

const ClosingCostList = () => {
  const [closingCostModalVisible, setClosingCostModalVisible] = useState<boolean>(false);
  const { user: { id: userId } } = useAuth();
  const {
    closingCostSettings,
    getClosingCostSettings,
    deleteClosingCostSettings,
    updateUserClosingCostOrder
  } = useContextUsers();
  const [editId, setEditId] = useState<string | number | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  // Refs for debounce implementation
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const latestRowsRef = useRef<ISettingItem[] | null>(null);
  const isUpdatingRef = useRef(isUpdating);
  const closingCostSettingsRef = useRef(closingCostSettings);
  const updateUserClosingCostOrderRef = useRef(updateUserClosingCostOrder);
  const getClosingCostSettingsRef = useRef(getClosingCostSettings);
  const userIdRef = useRef(userId);

  // Sync refs with current values
  useEffect(() => { isUpdatingRef.current = isUpdating; }, [isUpdating]);
  useEffect(() => { closingCostSettingsRef.current = closingCostSettings; }, [closingCostSettings]);

  // eslint-disable-next-line max-len
  useEffect(() => { updateUserClosingCostOrderRef.current = updateUserClosingCostOrder; }, [updateUserClosingCostOrder]);

  useEffect(() => { getClosingCostSettingsRef.current = getClosingCostSettings; }, [getClosingCostSettings]);
  useEffect(() => { userIdRef.current = userId; }, [userId]);

  useEffect(() => {
    if (closingCostSettings) return;
    getClosingCostSettings?.(userId);
  }, [closingCostSettings, getClosingCostSettings, userId]);

  // Cleanup timeout on unmount
  useEffect(() => () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }, []);

  const handleDelete = async (deleteId: string | number) => {
    if (deleteClosingCostSettings && getClosingCostSettings) {
      await deleteClosingCostSettings(deleteId);
      getClosingCostSettings(userId);
    }
  };

  const handleEdit = (itemId: string | number) => {
    setEditId(itemId);
    setClosingCostModalVisible(true);
  };

  const handleUpdate = (rows: ISettingItem[]) => {
    latestRowsRef.current = rows;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      const currentRows = latestRowsRef.current;

      if (!currentRows) return;

      const updateRows = currentRows.map(row => ({
        id: row.id,
        name: row.name,
        order: row.order,
        active: row.active,
      }));

      if (!closingCostSettingsRef.current || isUpdatingRef.current) return;

      try {
        setIsUpdating(true);

        if (updateUserClosingCostOrderRef.current) {
          await updateUserClosingCostOrderRef.current(
            { closingCosts: updateRows },
            userIdRef.current
          );
          if (getClosingCostSettingsRef.current) {
            await getClosingCostSettingsRef.current(userIdRef.current);
          }
        }
      } catch (error) {
        console.error('Error updating closing costs:', error);
      } finally {
        setIsUpdating(false);
      }
    }, 1000);
  };

  const handleAdd = () => {
    setEditId(null);
    setClosingCostModalVisible(true);
  };

  return (
    <React.Fragment>
      <SettingsList
        label="Closing Costs – Breakdown"
        draggable
        actions
        handleDeleteAction={handleDelete}
        handleEditAction={handleEdit}
        handleAddAction={handleAdd}
        items={closingCostSettings || []}
        handleUpdate={handleUpdate}
      />
      <ClosingCostModal
        open={closingCostModalVisible}
        onCancel={() => setClosingCostModalVisible(false)}
        closingCostId={editId ? Number(editId) : undefined}
      />
    </React.Fragment>
  );
};

export default ClosingCostList;