import { Space } from 'antd';
import React, { CSSProperties } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  InteractionMode,
  TooltipItem,
  ChartType,
} from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2';
import { commafy } from '../../../../utils/text';
import ContentCard from '../../ContentCard';
import { getCustomTooltip, ICustomTooltip } from '../index';
import ChartLegend from '../Legend';
import { IBarChartData } from '../types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IGetOptions {
  stacked: boolean;
  mode: InteractionMode;
  reversed: boolean;
  showTotal?: boolean;
  suffix?: string;
  prefix?: string;
  withLabels?: boolean;
  labelFont?: number;
  withYearLabel?: boolean;
}

export const getOptions = (
  { stacked, mode, reversed, showTotal, prefix, suffix, withLabels, labelFont, withYearLabel }: IGetOptions): any => ({
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        external: (context: ICustomTooltip) =>
          getCustomTooltip({ context, mode, isReversed: reversed, showTotal, prefix, suffix, withYearLabel }),
        mode,
        callbacks: {
          label: (item: TooltipItem<ChartType>) =>
            `${item.dataset.label}, ${item.raw}, ${item.dataset.backgroundColor}`,
        },
      },
      datalabels: {
        color: (context: Context) => context.dataIndex === 0 ? '#000000' : '#36af51',
        font: {
          size: 16,
        },
        align: 'end' as const,
        formatter: (value: number) => `$${commafy(value)}`,
        anchor: 'end' as const,
        borderRadius: 4,
        clamp: true,
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked,
        ticks: {
          color: '#8D94A2',
          font: {
            size: labelFont,
          },
        },
      },
      y: {
        min: 0,
        stacked,
        ticks: {
          color: '#8D94A2',
          font: {
            size: labelFont,
          },
          callback: (value: string | number) => `$${commafy(value)}`,
        },
        max: (context: Context) => {

          if (!withLabels) {
            return undefined;
          }

          const dataValues = context.chart.data.datasets
            .flatMap(dataset => dataset.data)
            .filter((value): value is number => typeof value === 'number');

          const maxValue = Math.max(...dataValues);

          const buffer = maxValue / 10;
          const magnitude = 10 ** Math.floor(Math.log10(buffer));
          const roundedBuffer = Math.ceil(buffer / magnitude) * magnitude;
          const adjustedBuffer = Math.ceil(roundedBuffer / magnitude) * magnitude;

          return maxValue + adjustedBuffer;
        },
      },
    },
  });

interface IBarChart extends CSSProperties {
  data?: IBarChartData;
  children?: React.ReactNode;
  legendControls?: React.ReactNode;
  hideLegend?: boolean;
  stacked?: boolean;
  mode?: InteractionMode;
  reversed?: boolean;
  loading?: boolean;
  showTotal?: boolean;
  suffix?: string;
  prefix?: string;
  withLabels?: boolean;
  labelFont?: number;
  withYearLabel?: boolean;
}

const BarChart = ({
  data,
  children,
  hideLegend,
  legendControls,
  stacked = false,
  mode = 'index',
  reversed = false,
  loading = false,
  showTotal = false,
  suffix,
  prefix = '$',
  withLabels,
  labelFont,
  withYearLabel,
  ...styles
}: IBarChart) => (
  <Space direction="vertical" size={20}>
    {!hideLegend && data && (
      <ChartLegend data={data} reversed={reversed}>
        {legendControls}
      </ChartLegend>
    )}

    {children}

    <ContentCard loading={loading}>
      {data && (
        <div className="chart-wrapper" style={{ ...styles, height: "500px !important" }}>
          <Bar plugins={withLabels ? [ChartDataLabels] : []}
            options={getOptions(
              { stacked, mode, reversed, showTotal, suffix, prefix, withLabels, labelFont, withYearLabel }
            )} data={data} />
        </div>
      )}
    </ContentCard>
  </Space>
);

BarChart.defaultProps = {
  data: undefined,
  children: undefined,
  legendControls: undefined,
  hideLegend: false,
  stacked: false,
  mode: 'index',
  reversed: false,
  loading: false,
  showTotal: false,
  prefix: undefined,
  suffix: undefined,
  withLabels: false,
  labelFont: 12,
  withYearLabel: false
};

export default BarChart;
