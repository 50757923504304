import React, { useState, useEffect, useCallback } from 'react'
import { Col, Row, Space } from 'antd'
import debounce from 'just-debounce-it'
import SettingsList from '../Settings/SettingsList'
import { ISettingItem, IUserSettingsParams } from '../../../../hooks/users'
import { useContextUsers } from '../../../../context/users'
import { useAuth } from '../../../../context/auth'

const Notifications = () => {
  const { user: { id } } = useAuth()
  const { settings, settingsLoading, updateSettings } = useContextUsers()
  const [localSettings, setLocalSettings] = useState(settings)

  useEffect(() => {
    setLocalSettings(settings)
  }, [settings])

  const debouncedUpdate = useCallback(
    debounce((newSettings: IUserSettingsParams) => {
      updateSettings?.(newSettings, id).catch(() => {
        setLocalSettings(settings)
      })
    }, 500),
    [id, settings, updateSettings]
  )

  const handleUpdate = (type: string, rows: ISettingItem[]) => {
    const newSettings = { ...localSettings, [type]: rows }

    // @ts-ignore - TS doesn't like dynamic keys
    setLocalSettings(newSettings)
    debouncedUpdate(newSettings)
  }

  return (
    <Row gutter={[20, 20]}>
      <Col xl={8} span={24}>
        <Space direction="vertical" size={20}>
          <SettingsList
            loading={settingsLoading}
            items={localSettings?.marketNotification || []}
            label="Market notifications"
            description="Receive interest rate reprice alerts."
            handleUpdate={(rows) => handleUpdate('marketNotification', rows)}
          />
          <SettingsList
            loading={settingsLoading}
            items={localSettings?.generalNotification || []}
            label="General notifications"
            handleUpdate={(rows) => handleUpdate('generalNotification', rows)}
          />
        </Space>
      </Col>
    </Row>
  )
}

export default Notifications