/* eslint-disable max-len */
import { IReportRow } from '../types/reports';
import { toFloat } from './text';

export const getInputsCount = (tableView: IReportRow[]) => {
  let fieldsCount = 0;

  Object.values(tableView).forEach((row) => {
    if (!row.active || !(row.tabIndex || row.subField?.tabIndex)) return;

    if (row.tabIndex) fieldsCount += 1;
    if (row.subField?.tabIndex) fieldsCount += 1;
    if (row.getPopoverContent) fieldsCount += 1;
  });

  return fieldsCount;
};

export const calculateUip = (loanAmount: number | string): number => toFloat(loanAmount) * 0.0175

export const getAprRelatedCostValue = (aprRelatedClosingCost: any) => {
  if (typeof aprRelatedClosingCost === 'object') return aprRelatedClosingCost?.value || 0;

  if (typeof aprRelatedClosingCost === 'string' || typeof aprRelatedClosingCost === 'number') return aprRelatedClosingCost;

  return 0;
};

export const calculateAPR = (interest: number, aprRelatedCostValue: number, loanAmount: number, loanPeriodInYears: number) =>
  ((interest + aprRelatedCostValue) / loanAmount / (loanPeriodInYears * 365 + Math.ceil(loanPeriodInYears / 4))) * 365 * 100;


export const calculateAPRFromLoan = (loan: any) => {
  const { loanAmount, interestRate, aprRelatedClosingCost, loanPeriodInYears } = loan;

  if (!loanAmount?.value || !interestRate?.value || !loanPeriodInYears?.value) return '0';

  const aprRelatedCostValue = getAprRelatedCostValue(aprRelatedClosingCost);

  if (!aprRelatedCostValue) return '0';

  const interestRateValue = toFloat(interestRate.value) / 100;

  const interest = (toFloat(loanAmount.value) + aprRelatedCostValue) * interestRateValue * toFloat(loanPeriodInYears.value);

  const apr = calculateAPR(interest, aprRelatedCostValue, loanAmount.value, loanPeriodInYears.value);

  return Number.isNaN(apr) ? '0' : apr.toFixed(3);
};

export const calculatePrincipalInterest = (loan: any, interestRate: number): number => {
  const { loanAmount, loanPeriodInYears, addUip, loanAmountWithUip, interestOnly } = loan;

  if (!loanAmount?.value || !interestRate) return 0;

  const R = addUip && loanAmountWithUip ? toFloat(loanAmountWithUip?.value) : toFloat(loanAmount.value);

  const r = toFloat(interestRate) / 100 / 12;
  const n = (toFloat(loanPeriodInYears?.value) || 30) * 12;

  const x = (1 + r) ** n;
  const M = Math.round(R * ((r * x) / (x - 1)));

  if (interestOnly) {
    let value;

    const rate = interestRate;

    if (R != null && rate != null) {
      value = (Number(R) * Number(rate)) / 100 / 12;

      return value
    }
  }

  return Number.isNaN(M) ? 0 : M;
}