import React, { useEffect, useState } from 'react';
import { Col, InputNumber, Row, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useReportSavingMonthly } from '../../../../../hooks/reports';
import { IBarChartData } from '../../../../Common/Charts/types';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';
import { useContextReports } from '../../../../../context/reports';
import MovingTo from '../../../../Common/MovingTo';
import BarChart from '../../../../Common/Charts/BarChart';
import { commafy } from '../../../../../utils/text';
import useDebounce from '../../../../../hooks/debounce';

interface ISavingForIncreasing {
  provided?: DraggableProvided;
}

const SavingForIncreasing = ({ provided }: ISavingForIncreasing) => {
  const name = 'savingForIncreasing';
  const [loanKey, setLoanKey] = useState<number | null>(null);
  const [monthlyIncrease, setMonthlyIncrease] = useState<number | null>(0); // Initialize to 0
  const form = useFormInstance();
  const { loans, handleLoansUpdate } = useContextReports();
  const movingTo = useWatch(['movingTo', name], form);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const calculateSaving = useReportSavingMonthly();
  const debouncedMonthlyIncrease = useDebounce(monthlyIncrease, 500);
  const [data, setData] = useState<IBarChartData | undefined>(undefined);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (!isSectionActive || !movingTo || !loans) return;

    const loanIndex = loans.findIndex(item => item.id === movingTo);

    if (loanIndex >= 0) {
      setLoanKey(loanIndex);
      const savedValue = loans[loanIndex]?.montlySavingIncrease;

      setMonthlyIncrease(savedValue !== undefined ? Number(savedValue) : 0);
    }
  }, [isSectionActive, movingTo, loans]);

  const handleCalculateSavings = async (monthlyIncreaseValue: string | undefined) => {
    if (!movingTo || !isSectionActive || !loans || !monthlyIncreaseValue) return;

    try {
       const res = await calculateSaving.fetch({
        id: movingTo,
        montlySavingIncrease: monthlyIncreaseValue,
      });

      if (res?.data) {
        const currentYear = new Date().getFullYear();
        const result = res.data.savingArray.map((value, i) => ({
          x: currentYear + i,
          y: parseFloat(value),
        }));

        const chartData: IBarChartData = {
          labels: result.map(item => String(item.x)),
          datasets: [
            {
              label: 'Savings Over Time',
              data: result.map(item => item.y),
              maxBarThickness: 15,
              inflateAmount: 1,
              backgroundColor: '#1e0c68',
              borderRadius: {
                topLeft: 4,
                topRight: 4,
              },
            },
          ],
        };

        setData(chartData);
      }
    } catch (error) {
      console.error('Error fetching savings data:', error);
    }
  };

  useEffect(() => {
    if (loanKey !== null && loans?.[loanKey]) {
      const prevFormValue = form.getFieldValue(['loans', loanKey, 'montlySavingIncrease']);

      if(Number(monthlyIncrease) === 0 && Number(prevFormValue) !== 0) {
        // form.setFieldValue(['loans', loanKey, 'montlySavingIncrease'], 0);
      } else {
        form.setFieldValue(['loans', loanKey, 'montlySavingIncrease'], monthlyIncrease);
      }
      

      handleCalculateSavings(debouncedMonthlyIncrease !== undefined ? String(debouncedMonthlyIncrease) : undefined);
    }
  }, [debouncedMonthlyIncrease, loanKey, loans]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOnPaymentChange = (value: number | null) => {
    setMonthlyIncrease(value ?? 0);
  };

  const handleLoanOptionChange = () => {
    handleLoansUpdate?.();
  }

  return (
    <ReportSection
      id={name}
      name={name}
      title='Impact of Increasing Monthly Payment on Mortgage Savings'
      provided={provided}
    >
      <Space
        direction='vertical' size={20} className="impact-container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Row style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
          <Col style={{ paddingTop: '10px', flex: '1' }}>
            <Space size={60} style={{ display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' }}>
              <TextWithLabel
                label='Total Interest Savings Over The Life Of The Loan'
                // eslint-disable-next-line no-restricted-globals
                value={`$${commafy(isNaN(calculateSaving.data?.data?.totalSaving ?? NaN)
                  ? 0 : calculateSaving.data?.data?.totalSaving)}`}
              />
              {windowWidth >= 867 && (
                <TextWithLabel
                  label='Loan Payoff Acceleration'
                  value={`${calculateSaving.data?.data?.yearsSaving || 0}
                  years, ${calculateSaving.data?.data?.monthsSaving || 0} months`}
                />
              )}
              {windowWidth >= 867 && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '150px', flexShrink: 0 }}>
                  <Typography.Text className="color-gray fs-0875 ws-nowrap" style={{ marginBottom: 9 }}>
                    Additional Monthly Payment
                  </Typography.Text>
                  <InputNumber
                    size='large'
                    formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={monthlyIncrease ?? undefined}
                    onChange={handleOnPaymentChange}
                    parser={(value) => Number(value?.replace(/\$\s?|(,*)/g, '') || 0)}
                    placeholder="$0"
                    min={0}
                    style={{ width: '100%' }}
                    controls={false}
                  />
                </div>
              )}
              {windowWidth >= 867 && (
                <MovingTo name={name} label='Select Loan Option' onChange={handleLoanOptionChange} />
              )}
            </Space>
          </Col>
          {windowWidth < 867 && (
            <>
              <Col span={24}>
                <TextWithLabel
                  label='Loan Payoff Acceleration'
                  value={`${calculateSaving.data?.data?.yearsSaving || 0} years,
                    ${calculateSaving.data?.data?.monthsSaving || 0} months`}
                />
              </Col>
              <Col span={24}>
                <InputNumber
                  size='large'
                  formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={monthlyIncrease ?? undefined}
                  onChange={handleOnPaymentChange}
                  parser={(value) => Number(value?.replace(/\$\s?|(,*)/g, '') || 0)}
                  placeholder="$0"
                  min={0}
                  style={{ width: '100%' }}
                  controls={false}
                />
              </Col>
              <Col span={24}>
                <MovingTo name={name} label='Select Loan Option' onChange={handleLoanOptionChange} />
              </Col>
            </>
          )}
        </Row>
        <BarChart mode='nearest' hideLegend data={data} loading={calculateSaving.loading} />
      </Space>
    </ReportSection>
  );
};

SavingForIncreasing.defaultProps = {
  provided: undefined,
};

export default SavingForIncreasing;
