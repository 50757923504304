/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Popover, Spin, Switch } from 'antd';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ILoan } from '../closingCostsDetails.types';
import { formatCurrency, parseCurrencyWithNegative } from '../../../../../utils/currency';
import { useContextReports } from '../../../../../context/reports';
import { useUpdateLoansClosingCostAmount } from '../../../../../hooks/loans';
import ClosingCostFormPopover from '../ClosingCostFormPopover';
import { useContextNotifications } from '../../../../../context/notifications';
import { EnumReportTypes, IReportRow } from '../../../../../types/reports';
import { EnumClosingCostType } from '../../../../../types/settings';
import { DragHandleContext } from '../LoanClosingCostSortableRow';
import { useReportClosingCostActiveUpdate } from '../../../../../hooks/reports';
import { commafyWithNegative } from '../../../../../utils/text';

const nonMutableColumnsMapping = {
  loanPoints: 'Loan Points',
  uip: 'Upfront ins. Premium',
}

const nonMutableColumns = Object.values(nonMutableColumnsMapping);
const MAIN_COLS_WIDTH = 20;
const COLS_WIDTH = 100;

const ColAddress = (address: string) => {
  // eslint-disable-next-line react/destructuring-assignment
  const optionMatch = address.match(/(.*) - (.*)/);

  if (!optionMatch) {
    return <span>{address.replaceAll(';', '')}</span>;
  }

  const optionPart = optionMatch[1];
  const restOfAddress = optionMatch[2];

  if (!restOfAddress) {
    return <span>{address.replaceAll(';', '')}</span>;
  }

  const parts = restOfAddress.split(' ');

  if (parts.length < 4) {
    return <span>{address.replaceAll(';', '')}</span>;
  }

  const strNumber = parts[0].replaceAll(';', '');
  const streetString = parts.slice(1, -3).join(' ').replaceAll(';', ' ');

  const city = parts[parts.length - 3]?.replace(',', '').replaceAll(';', ' ');
  const state = parts[parts.length - 2]?.replace(',', '').replaceAll(';', '');
  const zip = parts[parts.length - 1].replaceAll(';', '');

  if (!strNumber || !streetString || !city || !state || !zip) {
    return <span>{address.replaceAll(';', '')}</span>;
  }

  return (
    <div style={{ maxWidth: '200px' }}>
      <span>{optionPart} {' '}</span>
      <br />
      <span>{strNumber} {streetString} {' '}</span>
      <br />
      <span>{city}, {state}, {zip} {' '}</span>
    </div>
  );
};

const DragHandle: React.FC = () => {
  const { listeners, setActivatorNodeRef } = useContext(DragHandleContext);

  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const useClosingCostsTable = (
  updatingKey: string | null,
  setUpdatingKey: (value: string | null) => void,
) => {
  const form = useFormInstance();
  const loans: ILoan[] = useWatch('loans', form);
  const {
    isBorrower,
    reportId,
    reportLoanClosingCosts,
    reportClosingCosts,
    report,
    getReportClosingCosts,
    getReportLoanClosingCosts,
    setPreviewLoading
  } = useContextReports();

  const { openNotification } = useContextNotifications();
  const { fetch: fetchCostAmount, loading: updateLoading } = useUpdateLoansClosingCostAmount();
  const { fetch: fetchUpdateCsActive } = useReportClosingCostActiveUpdate();
  const [amountValue, setAmountValue] = useState<number>(0);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [fail, setFail] = useState<boolean>(false);
  const tableView: Record<string, IReportRow> = useWatch('tableView', form);
  const [currentClosingCosts, setReportLoanClosingCosts] = useState<any[]>([]);

  useEffect(() => {
    if (reportLoanClosingCosts) {
      setReportLoanClosingCosts(reportLoanClosingCosts);
    }
  }, [reportLoanClosingCosts]);

  if (!reportLoanClosingCosts || !loans) return { columns: [], dataSource: [] };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;

    setAmountValue(numericValue);
    setInputValue(commafyWithNegative(value));
  };

  const handleColumnClick = (key: string, amount: number) => {
    if (isBorrower) return;

    setEditingKey(key);
    setAmountValue(amount);
    setInputValue(commafyWithNegative(amount));
  };

  const handleBlur = async (loanId: number, closingCostId: number) => {
    if (!editingKey) return;
    setUpdatingKey(`${loanId}_${closingCostId}`);

    try {
      setPreviewLoading(true)

      const loanIdx = loans.findIndex((loan) => loan.id === loanId);
      const oldAmount = reportLoanClosingCosts.find(cost => cost.id === closingCostId)?.amount || 0;
      const numericAmount = parseFloat(parseCurrencyWithNegative(String(amountValue))) || 0;

      // Optimistically update local state
      const updatedCosts = reportLoanClosingCosts.map(cost =>
        cost.id === closingCostId ? { ...cost, amount: numericAmount } : cost
      );

      setReportLoanClosingCosts(updatedCosts);

      // Now, update the DB
      await fetchCostAmount({ amount: numericAmount }, closingCostId);
      await refetchData();

      const total = calculateColumnTotals(loanId);
      const newTotal = total - oldAmount + numericAmount;

      form.setFieldValue(['loans', loanIdx, 'closingCosts', 'value'], newTotal);
    } catch (error) {
      // Revert optimistic update on error
      setReportLoanClosingCosts(reportLoanClosingCosts);
      setFail(true);
      openNotification?.({
        message: 'Error',
        description: 'An error occurred while updating the closing cost amount',
        type: 'error',
      });
    } finally {
      setEditingKey(null);
      setUpdatingKey(null);
      setAmountValue(0);
      setInputValue('');
      setPreviewLoading(false);
    }
  };

  const calculateColumnTotals = (loanId: number) => reportLoanClosingCosts
    ?.filter((item) => item.loanId === loanId)
    .reduce((total, cost) => {
      const loan = loans.find((l) => l.id === loanId);

      if (
        (cost.text === nonMutableColumnsMapping.uip && (!tableView.uip?.active || loan?.addUip))
        // || (cost.text === nonMutableColumnsMapping.loanPoints && !tableView.loanPoints?.active)
      ) {
        return total;
      }

      // @ts-ignore report id exists
      const reportClosingCost = reportClosingCosts?.find(c => c.name === cost.name && c.reportId === reportId);

      if (reportClosingCost?.active === false) {
        return total;
      }

      return total + (cost.amount || 0);
    }, 0);

  const refetchData = async () => {
    await getReportClosingCosts?.();
    await getReportLoanClosingCosts?.();
  };

  const handleSwitchCheck = async (checked: boolean, reportClosingCostId: number | undefined) => {
    try {
      setPreviewLoading(true);

      await fetchUpdateCsActive({ active: checked }, reportClosingCostId);
      await refetchData();

      loans.forEach((loan) => {
        const loanIdx = loans.findIndex((l) => l.id === loan.id);
        const newTotal = calculateColumnTotals(loan.id);

        form.setFieldValue(['loans', loanIdx, 'closingCosts', 'value'], newTotal);
      });
    } catch (error) {
      openNotification?.({
        message: 'Error',
        description: 'An error occurred while updating the closing cost amount',
        type: 'error',
      });
    } finally {
      setPreviewLoading(false);
    }
  }

  const columns = [
    {
      title: 'Closing Costs',
      dataIndex: 'text',
      key: 'text',
      fixed: 'left' as 'left' | 'right',
      width: MAIN_COLS_WIDTH,
      render: (text: string, record: any) => {
        const allLoans = Object.keys(record)
          .filter((key) => key.startsWith('loan_'))
          .map((key) => ({
            ...record[key],
            text: record.text,
          }));

        const loanClosingCost = reportLoanClosingCosts?.find(cost => cost.id === record.id);

        const reportClosingCost = reportClosingCosts?.find(cost => cost.name === loanClosingCost?.name
          // @ts-ignore report id exists here
          && cost.reportId === reportId);

        const isRowActive = reportClosingCost?.active;

        const isRowDisabled = !(isRowActive !== false && reportClosingCost);

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '2px 2px',
            }}
          >
            {text.length > 20 ? (
              <Popover content={text}>
                {!nonMutableColumns.includes(text) && !isBorrower && <DragHandle />}
                <strong style={{ fontSize: '12px', marginLeft: 8 }}>
                  {text.length > 20 ? `${text.slice(0, 20)}...` : text}
                </strong>
              </Popover>
            ) : (
              <div style={{ padding: '2px 2px' }}>
                {!nonMutableColumns.includes(text) && !isBorrower && <DragHandle />}
                <strong style={{ fontSize: '12px', marginLeft: 8 }}>{text}</strong>
              </div>
            )}

            {!nonMutableColumns.includes(text) && (
              <div style={{ gap: '4px' }}>
                {!isBorrower && (
                  <Switch
                    size="small"
                    defaultChecked={!isRowDisabled}
                    onChange={(checked) => handleSwitchCheck(checked, reportClosingCost?.id)}
                  />
                )}
                <>
                  {!isBorrower && (
                    <>
                      {isRowDisabled ? (
                        <Button
                          type="link"
                          icon={<EditOutlined />}
                          style={{ marginRight: 4 }}
                          disabled
                        />
                      ) : (
                        <Popover
                          placement="right"
                          title={<span>Update <strong>{text}</strong></span>}
                          content={
                            <ClosingCostFormPopover
                              reportId={reportId ?? ''}
                              updateRows={allLoans}
                              refetchData={refetchData}
                              isEditMode
                            />
                          }
                        >
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            style={{ marginRight: 4 }}
                          />
                        </Popover>
                      )}
                    </>
                  )}
                </>
              </div>
            )}
          </div>
        );
      },
    },
    ...loans.filter(loan => !loan.hidden).map((loan: ILoan, i) => {
      const loanKey = `loan_${loan.id}`;
      const isRefinance = report?.reportType === EnumReportTypes.refinance;

      // @ts-ignore address exists here
      // eslint-disable-next-line no-nested-ternary
      const option = isRefinance
        ? (i === 0 ? "Current loan" : `Refi Option ${i}`)
        : `Option ${i + 1}`;
        
      // @ts-ignore address exists here
      const address = `${loan?.name || option} - ${loan.address ? loan.address : '(No Address)'}`;

      return {
        title: ColAddress(address),
        dataIndex: loanKey,
        key: loanKey,
        width: COLS_WIDTH,
        render: (value: any, record: any) => {
          const cellKey = `${record.id}_${loanKey}`;
          const closingCostId = value?.closingCostId;
          const isUpdating = updatingKey === `${loan.id}_${closingCostId}` && updateLoading;

          if (record.text === nonMutableColumnsMapping.uip && loan.addUip) {
            return '-';
          }

          const loanClosingCost = currentClosingCosts?.find(cost => cost.id === record.id);
          const reportClosingCost = reportClosingCosts?.find(cost => cost.name === loanClosingCost?.name
            // @ts-ignore report id exists here
            && cost.reportId === reportId);

          const isRowActive = reportClosingCost?.active;

          const isRowDisabled = !(isRowActive !== false && reportClosingCost);

          if (editingKey === cellKey && value?.editable && value.type !== EnumClosingCostType.prepaidInterest15Days
            && !isRowDisabled) {
            return (
              <Input
                value={inputValue}
                onChange={handleChange}
                onBlur={() => handleBlur(loan.id, closingCostId)}
                autoFocus
                prefix="$"
              />
            );
          }

          if (isUpdating) {
            return <Spin size="small" />;
          }

          return (
            <div
              onClick={() => value?.editable && !isRowDisabled
                ? handleColumnClick(cellKey, value?.amount || '0')
                : null}
              style={{ cursor: value?.editable && !isRowDisabled ? 'pointer' : 'default' }}
            >
              {value !== undefined ? formatCurrency(String(value.amount)) : ''}
            </div>
          );
        },
      };
    }),
  ];

  const uniqueClosingCosts = new Map<string, any>();

  currentClosingCosts.forEach((closingCost) => {
    const costKey = `${closingCost.text}`;

    if (!uniqueClosingCosts.has(costKey)) {
      uniqueClosingCosts.set(costKey, {
        id: closingCost.id,
        text: closingCost.text,
        amounts: {},
      });
    }

    const costGroup = uniqueClosingCosts.get(costKey);

    costGroup.amounts[`loan_${String(closingCost.loanId)}`] = {
      amount: closingCost.amount,
      closingCostId: closingCost.id,
      type: closingCost.type,
      editable: closingCost.editable,
    };
  });

  const dataSource = Array.from(uniqueClosingCosts.values()).map(
    (cost, index) => ({
      key: index,
      id: cost.id,
      text: cost.text,
      ...Object.fromEntries(
        Object.entries(cost.amounts).map(([loanKey, value]) => [
          loanKey,
          value,
        ])
      ),
    })
  ).filter((record) => {
    if (
      (record.text === nonMutableColumnsMapping.uip && !tableView.uip?.active) ||
      (record.text === nonMutableColumnsMapping.loanPoints && !tableView.loanPoints?.active)
    ) {
      return false;
    }

    return true;
  });


  return { columns, dataSource, fail, calculateColumnTotals };
};

export default useClosingCostsTable;
