import { Button, Col, Row, Space, Typography } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { INotificationItem, useNotifications, useNotificationsByType } from '../../../hooks/notifications';
import ContentCard from '../../Common/ContentCard';
import NotFoundContent from '../../Common/NotFoundContent';
import NotificationRow from './NotificationRow';

const Notifications = () => {
  const [notificationsList, setNotificationList] = useState<INotificationItem[]>();
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const notifications = useNotifications();
  const notificationsByType = useNotificationsByType();

  useEffect(() => {
    const fetchNotifications = activeFilter ? notificationsByType.fetch({ type: activeFilter }) : notifications.fetch();

    fetchNotifications.then((res) => {
      setNotificationList(res?.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    });
  }, [activeFilter]);

  return (
    <section>
      <Row>
        <Col xl={12} span={24}>
          <Space direction="vertical" size={20}>
            <Space direction="vertical" size={28}>
              <Typography.Title>Notifications</Typography.Title>
              <Space size={12}>
                <Button
                  type={!activeFilter ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': !!activeFilter })}
                  onClick={() => setActiveFilter(null)}
                >
                  All
                </Button>
                <Button
                  type={activeFilter === 'report' ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': activeFilter !== 'report' })}
                  onClick={() => setActiveFilter('report')}
                >
                  Report
                </Button>
                <Button
                  type={activeFilter === 'activity' ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': activeFilter !== 'activity' })}
                  onClick={() => setActiveFilter('activity')}
                >
                  Activity
                </Button>
                <Button
                  type={activeFilter === 'system' ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': activeFilter !== 'system' })}
                  onClick={() => setActiveFilter('system')}
                >
                  System
                </Button>
              </Space>
            </Space>
            <ContentCard loading={notifications.loading || notificationsByType.loading}>
              {notificationsList?.length ? (
                <Space direction="vertical" size={8}>
                  {notificationsList.map((item) => (
                    <NotificationRow key={item.id} item={item} />
                  ))}
                </Space>
              ) : (
                <NotFoundContent />
              )}
            </ContentCard>
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default Notifications;
