import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalProps, Table, Button, Popover } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import Form from 'antd/es/form';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { useContextNotifications } from '../../../../context/notifications';
import { ILoan } from './closingCostsDetails.types';
import useClosingCostsTable from './hooks/useClosingCostsTable';
import ClosingCostFormPopover from './ClosingCostFormPopover';
import { useContextReports } from '../../../../context/reports';
import LoanClosingCostSortableRow from './LoanClosingCostSortableRow';
import { useReportClosingCostsListUpdate } from '../../../../hooks/reports';

const LoansClosingCosts: React.FC<ModalProps> = ({ open, onCancel }) => {
  const form = Form.useFormInstance();
  const loans: ILoan[] = useWatch('loans', form);
  const [exportingPdf, setExportingPdf] = useState<boolean>(false);
  const { openNotification } = useContextNotifications();
  const [updatingKey, setUpdatingKey] = useState<string | null>(null);
  const [addPopoverOpen, setAddPopoverOpen] = useState<boolean>(false);
  const { fetch: updateClosingCostsList, loading: updateListLoading } = useReportClosingCostsListUpdate();
  const {
    reportId,
    isBorrower,
    reportClosingCosts,
    reportLoanClosingCosts,
    getReportClosingCosts,
    getReportLoanClosingCosts
  } = useContextReports();
  const [rows, setRows] = useState<any[]>([]);
  const initialDataSourceRef = useRef<any[]>([]);

  useEffect(() => {
    if (open) {
      refetchData();
    }
  }, [open]);

  const { columns, dataSource, fail, calculateColumnTotals } = useClosingCostsTable(
    updatingKey,
    setUpdatingKey,
  );

  useEffect(() => {
    if (!open) return;

    if (JSON.stringify(initialDataSourceRef.current) !== JSON.stringify(dataSource)) {
      initialDataSourceRef.current = dataSource;
      setRows(dataSource);
    }
  }, [open, dataSource]);

  const handleDragEnd = async (event: DragEndEvent) => {
    if(isBorrower) return;

    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = rows.findIndex((row) => row.key === active.id);
    const newIndex = rows.findIndex((row) => row.key === over.id);

    if (oldIndex === newIndex) return;

    const newRows = arrayMove(rows, oldIndex, newIndex);

    setRows(newRows);

    const finalUpdateRows = newRows.map((row, index) => {
      if (index === newRows.length - 1) return;
      const loanClosingCost = reportLoanClosingCosts?.find(cost => cost.id === row.id)

      if (!loanClosingCost) return;

      const reportClosingCost = reportClosingCosts?.find(cost =>
        // @ts-ignore there is report id there
        cost.reportId === reportId && cost.name === loanClosingCost.name)

      if (!reportClosingCost) return

      // eslint-disable-next-line consistent-return
      return {
        id: reportClosingCost.id,
        active: reportClosingCost.active, order: index + 1
      }
    })

    if (finalUpdateRows) {
      // @ts-ignore - reportId is not defined
      await updateClosingCostsList({ closingCosts: finalUpdateRows.filter(row => row !== undefined) }, reportId);
    }
  };

  const handleExportPdf = () => {
    setExportingPdf(true);

    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();

      const pdfColumns = columns.map((col) => {
        let headerText = '';

        if (typeof(col.title) === 'string') {
          headerText = col.title;
        } else {
          const loanId = parseInt(col.key.split('_')[1], 10);
          const loan = loans.find((l) => l.id === loanId);

          // @ts-ignore - there is a name property
          headerText = loan ? loan.address : '';
        }

        return { header: headerText, dataKey: col.key };
      });

      const pdfRows = dataSource.map((row) => {
        const transformedRow: Record<string, string> = {};

        pdfColumns.forEach((col) => {
          const value = row[col.dataKey as keyof typeof row];

          if (col.dataKey === 'text') {
            transformedRow[col.dataKey] = String(value || '');
          } else {
            transformedRow[col.dataKey] = value?.amount
              ? `$${value.amount.toLocaleString()}`
              : '$0';
          }
        });

        return transformedRow;
      });

      const totalsRow: Record<string, string> = {};

      pdfColumns.forEach((col) => {
        if (col.dataKey === 'text') {
          totalsRow[col.dataKey] = 'Total';
        } else {
          const loanId = parseInt(col.dataKey.split('_')[1], 10);
          const total = calculateColumnTotals ? calculateColumnTotals(loanId) : 0;

          totalsRow[col.dataKey] = total ? `$${total.toLocaleString()}` : '$0';
        }
      });
      pdfRows.push(totalsRow);

      autoTable(doc, {
        head: [pdfColumns.map((col) => col.header)],
        body: pdfRows.map((row) =>
          pdfColumns.map((col) => row[col.dataKey] || '')
        ),
        styles: { cellPadding: 5, fontSize: 10 },
        headStyles: {
          fillColor: [30, 12, 104], // Custom header color (RGB)
          textColor: [255, 255, 255], // Custom text color (White)
          fontStyle: 'bold',
        },
        theme: 'striped',
        didParseCell: (data) => {
          // This is for making bold the first column
          if (data.column.index === 0) {
            // eslint-disable-next-line no-param-reassign
            data.cell.styles.fontStyle = 'bold';
          }

          // This is for making bold the bottom (total) row
          if (data.row.index === pdfRows.length - 1) {
            // eslint-disable-next-line no-param-reassign
            data.cell.styles.fontStyle = 'bold';
          }
        }
      });

      // Save the PDF
      doc.save('closing-costs.pdf');
    } catch (error) {
      openNotification?.({
        message: `Error while generating PDF: ${error}`,
        type: 'error',
      });
    } finally {
      setExportingPdf(false);
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e);
    setAddPopoverOpen(false);
  };

  const refetchData = async () => {
    await getReportClosingCosts?.();
    await getReportLoanClosingCosts?.();

    // Immediately update the data source so UI reflects changes
    setRows(dataSource);
  };

  return (
    <Modal
      open={open}
      title="Manage Loans Closing Costs"
      onCancel={handleCancel}
      width={loans && loans.filter(item => !item.hidden).length < 4 ? '40%' : '80%'}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          key="export"
          className="ant-btn ant-btn-primary"
          onClick={handleExportPdf}
          loading={exportingPdf}
        >
          Export PDF
        </Button>,
        reportId && !isBorrower && (
          <Popover
            key="add"
            className="ant-btn ant-btn-primary"
            content={
              <ClosingCostFormPopover
                reportId={reportId}
                setPopoverOpen={setAddPopoverOpen}
                refetchData={refetchData}
              />
            }
            title="Add Closing Cost"
            trigger="click"
            open={addPopoverOpen}
            onVisibleChange={(visible) => setAddPopoverOpen(visible)}
          >
            <Button onClick={() => setAddPopoverOpen((prev) => !prev)}>Add Closing Cost</Button>
          </Popover>
        ),
      ]}
    >
      {fail && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          Failed to update closing cost amount
        </div>
      )}

      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={rows.map((row) => row.key)} strategy={verticalListSortingStrategy}>

          <Table
            className='custom-table'
            columns={columns}
            dataSource={rows}
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
            size="large"
            components={{
              body: {
                row: LoanClosingCostSortableRow
              },
            }}
            // eslint-disable-next-line react/no-unstable-nested-components
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  {loans.filter(loan => !loan.hidden).map((loan: ILoan) => (
                    <Table.Summary.Cell index={loan.id} key={`total_${loan.id}`}>
                      <strong>
                        ${calculateColumnTotals ? calculateColumnTotals(loan.id)?.toLocaleString() || '0.00' : '0.00'}
                      </strong>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            )}
            style={{ minWidth: '100%', fontSize: '12px' }}
          />
        </SortableContext>
      </DndContext>
    </Modal>
  );
};

export default LoansClosingCosts;