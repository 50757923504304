/* eslint-disable @typescript-eslint/no-empty-function */
import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Reports/Edit';
import { displayName } from '../../../config';
import ReportsProvider from '../../../context/reports';

const ReportEdit = (): JSX.Element => {
  document.title = `${displayName}: Report Edit`;

  return (
    <Layout>
      <ReportsProvider value={{
        isBorrower: false,
        previewLoading: false,
        closingCostsLoading: false,
        setPreviewLoading: () => { },
        setClosingCostsLoading: () => { }
      }}>
        <Content isLoading={false} />
      </ReportsProvider>
    </Layout>
  );
};

export default ReportEdit;
