import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { createContext } from 'react';

export const DragHandleContext = createContext<any>(null);

const LoanClosingCostSortableRow = ({ className, style, ...restProps }: any) => {
  const {  listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: restProps['data-row-key'],
  });

  const sortableStyle = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DragHandleContext.Provider value={{ listeners, setActivatorNodeRef }}>
      <tr
        ref={setNodeRef}
        style={sortableStyle}
        {...restProps}
      />
    </DragHandleContext.Provider>
  );
};

export default LoanClosingCostSortableRow
