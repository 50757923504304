import { Modal, ModalFuncProps, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useCustomerPaymentMethods } from '../../../../../../hooks/stripe';
import PaymentsList from '../../../../../Common/PaymentsList';
import { JsonResult } from '../../../../../../types';

interface IPaymentMethodModal extends ModalFuncProps {
  id?: string;
  data?: JsonResult;
}

const PaymentMethodModal = ({ open, onOk, onCancel }: IPaymentMethodModal) => {
  const customerPaymentMethods = useCustomerPaymentMethods();

  const loadPaymentMethods = () => {
    customerPaymentMethods.fetch();
  };

  const handleOk = () => {
    onCancel?.();
    onOk?.();
  };

  useEffect(() => {
    if (!open) return;
    loadPaymentMethods();
  }, [open]);

  const paymentMethods = customerPaymentMethods.data?.data.data;

  return (
    <Modal closable={false} footer={null} open={open} onCancel={onCancel} wrapClassName="modal">
      <Space direction="vertical" size={20}>
        <Typography.Title level={2} style={{ color: '#000' }}>Change Payment Method</Typography.Title>
        <PaymentsList
          paymentMethods={paymentMethods}
          selectedId={null}
          handleNext={handleOk}
          handleBack={onCancel}
          reloadPaymentMethods={loadPaymentMethods}
          loading={customerPaymentMethods.loading}
          confirmButtonText="Continue"
          cancelButtonText="Cancel"
        />
      </Space>
    </Modal>
  );
};

PaymentMethodModal.defaultProps = {
  id: undefined,
  data: {},
};

export default PaymentMethodModal;
