import React, { useCallback, useEffect, useState, MouseEvent } from 'react';
import { Button, Popover, Switch, Typography, Form } from 'antd';
import Icon from '@ant-design/icons';
import clsx from 'clsx';
import { useWatch } from 'antd/es/form/Form';
import { EyeOff } from '../../../../../Common/Icon';
import { IReportLoan, useReportLoanHidden } from '../../../../../../hooks/reports';
import { useContextReports } from '../../../../../../context/reports';
import { COLORS } from '../../../../../../utils/cssUtils';

import styles from './index.module.less';
import { EnumReportTypes } from '../../../../../../types/reports';

const HideLoanTable = () => {
  const form = Form.useFormInstance();
  const loans = useWatch('loans', form) as any[];
  const toggleLoan = useReportLoanHidden?.()
  const [hidden, setHidden] = useState<number>(0);
  const { handleLoansUpdate, report } = useContextReports();

  const isRefinance = report?.reportType === EnumReportTypes.refinance;

  useEffect(() => {
    if (!loans) return;

    const data: { [key: string]: boolean | string } = {};

    loans.forEach((item: IReportLoan) => {
      data[item.id] = item.hidden;
    });

    setHidden(loans ? Object.values(loans)?.filter((row: any) => row.hidden).length : 0);
    form.setFieldsValue(data);
  }, [loans]);

  const onChange = (checked: boolean, id: string) => {
    if (checked) {
      setHidden(hidden - 1);
    } else {
      setHidden(hidden + 1);
    }

    toggleLoan.fetch({ hidden: checked }, id);
    const loanIndex = loans?.findIndex((loan: IReportLoan) => loan.id === id);

    if (loanIndex !== -1) {
      const updatedLoans = [...loans];

      updatedLoans[loanIndex] = {
        ...updatedLoans[loanIndex],
        hidden: !!checked,
      };

      form.setFieldValue('loans', updatedLoans);
      handleLoansUpdate?.();
    }
  };

  const handleMouseEnter = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.White;
    e.currentTarget.style.color = COLORS.White;
  }, []);
  
  const handleMouseLeave = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.BorderGrey;
    e.currentTarget.style.color = COLORS.BorderGrey;
  }, []);

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      showArrow={false}
      content={
        <div>
          {loans &&
            loans.map((loan: IReportLoan, index: number) => {
              let { address, name } = loan;
  
              address = address?.toLocaleUpperCase();
              name = name?.toLocaleUpperCase();
  
              let delimiter = '';
  
              if (address?.includes(';')) {
                delimiter = ';';
              } else if (address?.includes(',')) {
                delimiter = ',';
              }
              const address1 = delimiter ? address?.split(delimiter)[0] : address;
              const address2 = delimiter ? address?.replace(`${address1}${delimiter}`, '') : null;
              const hasAddress = address1 || address2;
              const addressPart = hasAddress ? `${address1 || ''}` : '';
              const addressFull = `${addressPart} ${address2 || ''}`.trim();
              
              let result = '';

              if (isRefinance) {
                if (index === 0) {
                  result = `Current Loan ${addressFull ? '-' : ''} ${addressFull}`;
                } else {
                  result = `Refi Option ${index} ${addressFull ? '-' : ''} ${addressFull}`;
                }
              } else {
                result = (hasAddress || name) ? `${name || `Option ${index + 1} - ` || ''} 
                  ${name && addressFull ? '-' : ''} ${addressFull}` : name || `Option ${index + 1}`;
              }
  
              return (
                <div key={loan.id} className={styles.item}>
                  <div className={clsx(styles.row)}>
                    <div className={styles.content}>
                      <Form.Item noStyle>
                        <Switch
                          defaultChecked={!loan.hidden}
                          onChange={(checked) => onChange(!checked, loan.id)}
                        />
                      </Form.Item>
                      <Typography className={styles.label}>
                        {result}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      }
    >
  
      <Button
        ghost
        size='large'
        type='default'
        icon={<Icon component={EyeOff}/>}
        style={{
          borderColor: COLORS.BorderGrey,
          color: COLORS.BorderGrey
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {`${(loans && loans.length - hidden) || ''} Shown loan${loans && loans.length - hidden === 1 ? '' : 's'}`}
      </Button>
    </Popover>
  );
};

export default HideLoanTable;
