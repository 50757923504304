/* eslint-disable react/require-default-props */
import React from 'react';
import { Typography } from 'antd';
import styles from './index.module.less';

interface IAuth {
  title?: string;
  text?: string;
  children: React.ReactNode;
  underline?: boolean;
}

const Auth = ({ title, text, children, underline }: IAuth) => {
  const underlineText = underline ? "Free for 30 Days" : '';

  let firstPart = title;
  let secondPart = '';

  if (underline) {
    if (title?.includes(underlineText)) {
      [firstPart, secondPart] = title.split(underlineText);
    }
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.textBox}>
          <Typography.Title
            className={styles.title}
          >
            {firstPart}
            <span className={styles.underlineText}>
              {underlineText}
            </span>
            {secondPart}
          </Typography.Title>
          <Typography.Text className={styles.text}>{text}</Typography.Text>
        </div>
        {children}
      </div>
    </section>
  );
};

Auth.defaultProps = {
  underline: false,
};

export default Auth;
