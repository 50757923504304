import Icon from '@ant-design/icons';
import { Button, Typography, Image } from 'antd';
import { useState, SetStateAction, Dispatch, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import printJS from 'print-js';
import { jsPDF } from 'jspdf';
import { toPng } from 'html-to-image';

import { useContextNotifications } from '../../../../context/notifications';
import { useContextReports } from '../../../../context/reports';
import { IReportShareParams, useReportShare } from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import { Download, Print, Share } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';
import ShareModal from '../../../Pages/Reports/ShareModal';
import styles from '../../index.module.less';
import { IReportRow } from '../../../../types/reports';

interface IHeaderProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ setLoading }: IHeaderProps) => {
  const { id: reportId } = useParams();
  const { report, form } = useContextReports();
  const { openNotification } = useContextNotifications();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);
  const reportShare = useReportShare();
  const location = useLocation();

  useEffect(() => {
    if(!report) {
      return
    }

    if(report.name) {
      document.title = report?.name
    }
  }, [report])

  const handleDownloadPdf = async () => {
    setIsPdfLoading(true);

    const reportsElement = document.getElementById('reportToPdf');
    const reportAvatar = document.querySelectorAll("img[alt='User Avatar']");
    const originalSrc = reportAvatar[0]?.getAttribute('src');

    try {
      if (!reportsElement) return;

      if (reportAvatar && originalSrc) {
        reportAvatar.forEach((element) => {
          element.setAttribute('src', '/avatar-placeholder.png');
        });
      }

      reportsElement.setAttribute('style', `height: auto; overflow: visible`);

      const fullHeight = reportsElement.scrollHeight;
      const fullWidth = reportsElement.scrollWidth;

      const originalStyle: string | null = reportsElement.getAttribute('style');

      reportsElement.setAttribute('style', `width: ${fullWidth}px; height: ${fullHeight}px; ${originalStyle}`);

      const dataUrl = await toPng(reportsElement, {
        cacheBust: true,
        skipAutoScale: true,
        width: fullWidth,
        height: fullHeight,
        style: {
          background: 'white',
          width: `${fullWidth}px`,
          height: `${fullHeight}px`,
        },
      });

      if (originalStyle) {
        reportsElement.setAttribute('style', originalStyle);
      }
      const pageHeight = (842 * fullWidth) / 595; // aspect ratio as A4
      const numPages = Math.ceil(fullHeight / pageHeight);

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [pageHeight, fullWidth],
        putOnlyUsedFonts: true,
      });

      for (let page = 0; page < numPages; page++) {
        const y = page * pageHeight;

        if (page > 0) {
          pdf.addPage();
        }
        pdf.addImage(dataUrl, 'PNG', 0, -y, fullWidth, fullHeight, undefined, 'FAST');
      }

      pdf.save(`${report?.name ?? 'Report'}`);
      if (reportAvatar && originalSrc) {
        reportAvatar.forEach((element) => {
          element.setAttribute('src', originalSrc);
        });
      }
    } catch (err) {
      openNotification?.({
        message: `Error while generating PDF: ${err}`,
        type: 'error',
      });
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleShare = (data: JsonResult) => {
    handleModalClose();
    reportShare.fetch(data as IReportShareParams).then(() => {
      openNotification?.({
        message: 'The report was shared successfully',
        type: 'success',
      });
    });
  };

  const handlePrint = async () => {
    setIsPdfLoading(true);
    setLoading(true);
    const fullHeight = 9500;
    const fullWidth = 1126;
    const pageHeight = (842 * fullWidth) / 595; // aspect ratio as A4
    const reportView = form?.getFieldValue('reportView') as IReportRow;
    const reportViewArray = Object.values(reportView);
    const activeSections = reportViewArray.filter((section) => section?.active).sort((a, b) => a.order - b.order);

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [pageHeight, fullWidth],
      putOnlyUsedFonts: true,
    });


    // eslint-disable-next-line no-promise-executor-return
    new Promise((resolve) => setTimeout(resolve, 4000))
      .then(async () => {
        const reportAvatar = document.querySelectorAll("img[alt='UsWer Avatar']");
        const originalSrc = reportAvatar[0]?.getAttribute('src');

        const elements: Array<HTMLElement> = [];

        const loanComparisons = document.getElementById('loanComparisons');

        if (loanComparisons) {
          elements.push(loanComparisons);
        }

        for (let i = 0; i < activeSections.length; i++) {
          const section = activeSections[i];
          const element = document.getElementById(section.name);

          if (element) {
            elements.push(element);
          }
        }

        const reportsElement = document.getElementById('reportToPdf');

        const originalStyle = reportsElement ? reportsElement.getAttribute('style') : '';

        if (reportsElement)
          reportsElement.setAttribute(
            'style',
            `${originalStyle}; width:${fullWidth}px; height:${fullHeight}px ; overflow: visible`);

        try {
          if (!reportsElement) return;

          if (reportAvatar && originalSrc) {
            reportAvatar.forEach((element) => {
              element.setAttribute('src', '/avatar-placeholder.png');
            });
          }

          for (let i = 0; i < elements.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const dataUrl = await toPng(elements[i], {
              cacheBust: true,
              skipAutoScale: true,
              width: fullWidth,
              height: pageHeight,
              style: {
                background: 'white',
                width: `${fullWidth}px`,
                height: `${pageHeight}px`,
              },
            });

            if (i > 0) {
              pdf.addPage();
            }
            pdf.addImage(dataUrl, 'PNG', 0, 0, fullWidth, pageHeight, undefined, 'MEDIUM');
          }
          const base64Pdf = pdf.output('datauristring').split(',')[1];

          if (reportAvatar && originalSrc) {
            reportAvatar.forEach((element) => {
              element.setAttribute('src', originalSrc);
            });
          }

          if (reportsElement) reportsElement.setAttribute('style', originalStyle || '');

          printJS({
            printable: base64Pdf,
            type: 'pdf',
            modalMessage: 'Preparing document for printing...',
            base64: true,
          });
        } catch (err) {
          openNotification?.({
            message: `Error while printing: ${err}`,
            type: 'error',
          });
        } finally {
          setIsPdfLoading(false);
        }
      })
      .finally(() => {
        setLoading(true);
      });
  };

  const reloadBack = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/edit`;
  };

  return (
    <header className={styles.header}>
      <Loading visible={reportShare.loading || isPdfLoading} absolute />
      <div className={styles.headerContent}>
        <ShareModal
          open={isModalOpen}
          onCancel={handleModalClose}
          onOk={handleShare}
          data={{ link: window.location.href, reportId }}
        />
        <div className="flex-col gap-8">
          {window.outerWidth < 768 &&
            <div className={styles.logo}>
              <Image
                preview={false}
                src={report?.User?.logo || '/logo.png'}
                alt="Company Logo"
                height={50}
                style={{ objectFit: 'contain' }}
              />
            </div>
          }
          <Typography.Title
            level={2}
            style={{ color: 'black' }}>{report?.User?.companyName || 'Company Name'}</Typography.Title>
          {report?.User?.websiteLink && (
            <Typography.Text>
              <Link
                to={
                  report?.User.websiteLink.startsWith('http')
                    ? report?.User.websiteLink
                    : `https://${report?.User.websiteLink}`
                }
                target="_blank"
                className="fs-1 fw-400 color-gray"
              >
                {report?.User.websiteLink}
              </Link>
            </Typography.Text>
          )}
        </div>
        <div className="flex-row flex-align-center gap-8">
          {location.pathname.includes('/preview') && (
            <Button type="default" size="large" className="btn-default-dark-blue" onClick={() => reloadBack()}>
              Back to Edit
            </Button>
          )}
          <Button
            type="default"
            size="large"
            className="btn-default-dark-blue"
            icon={<Icon component={Share} />}
            style={{ minWidth: 40 }}
            onClick={handleModalOpen}
          />
          <Button
            type="default"
            size="large"
            className="btn-default-dark-blue d-m-none"
            style={{ minWidth: 40 }}
            icon={<Icon component={Print} />}
            onClick={handlePrint}
          />
          <Button type="primary" size="large" className="d-m-none" onClick={handleDownloadPdf} download>
            Download PDF
          </Button>
          <Button
            type="default"
            size="large"
            onClick={handleDownloadPdf}
            icon={<Icon component={Download} />}
            style={{ minWidth: 40, alignItems: 'center', justifyContent: 'center' }}
            className="d-lg-none d-m-flex btn-default-dark-blue"
            download
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
