export const formatCurrency = (value: string): string => {
    const numericValue = value.replace(/[^0-9.]/g, '');

    // eslint-disable-next-line prefer-const
    let [whole, decimal] = numericValue.split('.');

    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return decimal !== undefined ? `$${whole}.${decimal}` : `$${whole}`;
  };
  
  export const parseCurrency = (formattedValue: string): string =>
    formattedValue.replace(/[$,]/g, '');

  export const parseCurrencyWithNegative = (value: string) => {
    const match = value.match(/-?(\d+\.?\d*|\.\d+)/);

    return match ? match[0] : '';
  };
