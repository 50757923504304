import { useEffect, useState } from "react";
import { Button, Checkbox, Popover } from "antd";
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import LoansClosingCosts from "./LoansClosingCosts";
import './closingCosts.less'
import { useReportCSUpdate } from "../../../../hooks/reports";
import { useContextReports } from "../../../../context/reports";

const DETAILS_AVAILABLE_DEFAULT = false

const ClosingCostsDetails = () => {
	const form = useFormInstance();
	const [detailsAvailable, setDetailsAvailable] = useState<boolean>(DETAILS_AVAILABLE_DEFAULT);
	const [closingCostsOpen, setClosingCostsOpen] = useState<boolean>(false);
	const {
		reportId,
		report,
		getReport,
		closingCostsLoading,
		handleLoansUpdate
	} = useContextReports();
	const { fetch: updateReport, loading } = useReportCSUpdate();

	useEffect(() => {
		if (report && report.csActive !== undefined) {
			setDetailsAvailable(report.csActive);
		}
	}, [report]);

	const handleUpdateDetailsAvailable = async (available: boolean) => {
		await updateReport({ csActive: available }, reportId)
			.then(() => {
				setDetailsAvailable(available);
				getReport?.();
			})

		if (!available) {
			// Recalculate closing costs
			const loans = form.getFieldValue('loans') || [];


			loans.forEach((loan: any, i: number) => {
				const total = loan?.loanClosingCosts.reduce((acc: number, cost: any) => {
					if (cost.amount) {
						return acc + cost.amount;
					}

					return acc;
				}, 0);

				form.setFieldValue(['loans', i, 'closingCosts', 'value'], total);
			});
		}

		handleLoansUpdate?.();
	}

	return (
		<div className="cost-options">
			<LoansClosingCosts open={closingCostsOpen} onCancel={() => setClosingCostsOpen(false)} />
			<Button
				type="default"
				onClick={() => setClosingCostsOpen(true)}
				disabled={detailsAvailable}
				loading={closingCostsLoading}
			>
				Details
			</Button>
			<Popover content="Custom amount.">
				<Checkbox
					onChange={(e) => handleUpdateDetailsAvailable(e.target.checked)}
					checked={detailsAvailable}
					disabled={loading}
				/>
			</Popover>
		</div>
	)
}

export default ClosingCostsDetails;
